//
//
//
//
//
//
//

import buttonStyleMixin from '../mixins/button-style';

export default {
  name: 'ButtonRouter',
  mixins: [buttonStyleMixin],
  props: {
    to: { type: String, default: '/' },
  },

};
























import ButtonRouter from '@/shared/components/ButtonRouter.vue';

export default {
  name: 'Lifestyle_CTA',
  components: {
    ButtonRouter,
  },
  props: {
    id: {
      type: String,
      default: '',
    },
  },
  computed: {
    cta() {
      return { text: 'Shop Now', link: '/shop' };
    },
  },
};
